<template>
  <swiper v-bind="$attrs" v-on="$listeners" :lazy="true">
    <slot />
  </swiper>
</template>

<script>

import { Navigation, Thumbs, Keyboard } from 'swiper'

import { SwiperCore } from 'swiper-vue2'

SwiperCore.use([Navigation, Thumbs, Keyboard])

export default {
  name: 'SwiperSlider',
  inheritAttrs: false,
};
</script>

<style lang="scss">

  .swiper-container {

    .swiper-button {

      &-prev,
      &-next {
        color: gold;
        background-color: #111;
        margin: 0;
        transform: translateY(-50%);
        width: 1.875rem;
        height: 1.875rem;

        &::after {
          font-weight: 700;
          font-size: 0.688rem;
        }

        &.swiper-button-disabled {
          cursor: pointer;
          pointer-events: auto;
        }
      }

      &-next {
        right: 0;
      }

      &-prev {
        left: 0;
      }
    }
  }
</style>