var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.available
    ? _c("div", { staticClass: "product__item" }, [
        _vm.colorAvailable
          ? _c("div", { staticClass: "product__blocked" }, [
              _c("span", { staticClass: "product__blocked-content" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._translate("Not available in this color")) +
                    "\n    "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        this.getPreviewImages.length
          ? _c(
              "div",
              { staticClass: "dark-gray product__preview-image-container" },
              [
                _c(
                  "swiper-slider",
                  {
                    staticClass: "product__preview-image-slider",
                    attrs: {
                      thumbs: { swiper: _vm.thumbsSwiper },
                      navigation: true,
                      keyboard: { enabled: true, onlyInViewport: true },
                    },
                    on: {
                      swiper: _vm.setPreviewSwiper,
                      slideChange: _vm.handleSlideChange,
                    },
                  },
                  _vm._l(
                    this.showAllImages
                      ? this.getPreviewImages
                      : this.getPreviewImages.slice(0, this.thumbnailsToShow),
                    function (item, index) {
                      return _c("swiper-slide", { key: index }, [
                        _c(
                          "a",
                          {
                            staticClass: "db",
                            attrs: {
                              href: _vm.href,
                              title: _vm.title,
                              "aria-hidden": "true",
                              tabindex: "-1",
                            },
                          },
                          [
                            _vm.isImageLoading
                              ? _c("img", {
                                  attrs: { src: "/build/static/loader.gif" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isImageLoading,
                                  expression: "!isImageLoading",
                                },
                              ],
                              staticClass:
                                "swiper-lazy v-mid mw-none w-100 lazyload product__image",
                              attrs: {
                                src: item.src,
                                srcset: item.srcset,
                                alt: _vm.title,
                              },
                              on: { load: _vm.handleImageLoad },
                            }),
                          ]
                        ),
                      ])
                    }
                  ),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "product__thumbnails" },
          [
            _c(
              "swiper-slider",
              {
                staticClass: "product__thumbnails-slider",
                attrs: {
                  slidesPerView: "auto",
                  spaceBetween: 8,
                  "watch-slides-visibility": "",
                  "watch-slides-progress": "",
                },
                on: { swiper: _vm.setThumbsSwiper },
              },
              [
                _vm._l(
                  this.getThumbnails.slice(0, this.thumbnailsToShow),
                  function (image, index) {
                    return _c("swiper-slide", { key: index }, [
                      _c("div", { staticClass: "product__thumbnails-item" }, [
                        _c("img", { attrs: { src: image } }),
                      ]),
                    ])
                  }
                ),
                _vm._v(" "),
                !this.showAllImages &&
                this.getThumbnails.length > this.thumbnailsToShow
                  ? _c("swiper-slide", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "product__thumbnails-item product__thumbnails-show-more",
                          attrs: { href: _vm.href },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          +" +
                              _vm._s(
                                this.getThumbnails.slice(this.thumbnailsToShow)
                                  .length
                              ) +
                              "\n          "
                          ),
                          _c("img", {
                            attrs: {
                              src: this.getThumbnails[this.thumbnailsToShow],
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "product__info" }, [
          _c("div", { staticClass: "w-100 w-50-l" }, [
            _c("h3", { staticClass: "product__title serif" }, [
              _c("a", { attrs: { href: _vm.href } }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]),
            _vm._v(" "),
            _vm.product.vPromo && _vm.store.state.isPartner === false
              ? _c("p", { staticClass: "product__promo" }, [
                  _vm._v(_vm._s(_vm.product.vPromo)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "product__price" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._translate("From")) +
                  ": " +
                  _vm._s(_vm._f("toEur")(_vm.computedCost)) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product__meta" },
            [
              _c("category-color-selector", {
                attrs: {
                  colors: _vm.colors,
                  product: _vm.product,
                  "init-color": _vm.colors[0],
                  href: _vm.href,
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "product__add-btn fw7 sans-serif br2 black bg-yellow pa2 bw0 pointer f5 outline-0 dim ml3",
                  attrs: { href: _vm.href },
                },
                [_vm._v(_vm._s(_vm._translate("Buy")))]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }