var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "length__wrapper" },
    [
      _c("div", { staticClass: "length__inputs" }, [
        _c(
          "div",
          { staticClass: "length__block" },
          [
            _c("div", { staticClass: "length__block-title" }, [
              _vm._v(_vm._s(_vm._translate("Length"))),
            ]),
            _vm._v(" "),
            _vm.custom
              ? [
                  _c("div", { staticClass: "length__custom-input-wrapper" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customLength,
                          expression: "customLength",
                        },
                      ],
                      staticClass:
                        "length__block-select product__select length__custom-input",
                      attrs: {
                        min: _vm.customMin,
                        max: _vm.customMax,
                        placeholder: _vm._translate("Desired length"),
                      },
                      domProps: { value: _vm.customLength },
                      on: {
                        keypress: _vm.onlyNumbers,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.customLength = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]
              : [
                  _vm.state.selectedOptions.v_len
                    ? _c(
                        "div",
                        {
                          staticClass: "length__block-select product__select",
                          attrs: { tabindex: "1" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.state.selectedOptions.v_len.vMinLen + "cm"
                            ) + "\n          "
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "length__block-variants product__variants length",
                            },
                            [
                              _vm._l(
                                _vm.const_v_len,
                                function (length_variant) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "length__block-variant",
                                      class: {
                                        "length__block-variant_active":
                                          length_variant.id ===
                                          _vm.state.selectedOptions.v_len.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeVariant(
                                            length_variant,
                                            "v_len"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(length_variant.vMinLen + "cm") +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              !_vm.custom
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "length__block-custom-length nowrap",
                                      on: { click: _vm.changeCustom },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._translate(
                                            "Length to the cm exact"
                                          )
                                        ) + "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
            _vm._v(" "),
            _vm.custom && _vm._store.isMobile()
              ? [
                  this.customMax < _vm.customLength
                    ? _c("div", { staticClass: "length__error" }, [
                        _vm._v(
                          "Max " + _vm._s(this.customMax) + " cm\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  this.customMin > _vm.customLength
                    ? _c("div", { staticClass: "length__error" }, [
                        _vm._v(
                          "Min " + _vm._s(this.customMin) + " cm\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm._store.isMobile()
              ? _c(
                  "div",
                  {
                    staticClass:
                      "length__block-custom-block length__block-custom-block_mobile",
                  },
                  [
                    !_vm.custom
                      ? _c(
                          "div",
                          {
                            staticClass: "length__block-custom-length",
                            on: { click: _vm.changeCustom },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._translate("Length to the cm exact")) +
                                "\n        "
                            ),
                          ]
                        )
                      : [
                          _c(
                            "div",
                            {
                              staticClass:
                                "length__block-custom-length sans-serif",
                              on: { click: _vm.changeCustom },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._translate("Remove custom length")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("toEur")(_vm.price))),
                          ]),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "length__block" }, [
          _c("div", { staticClass: "length__block-title" }, [
            _vm._v(_vm._s(_vm._translate("Depth"))),
          ]),
          _vm._v(" "),
          _vm.state.selectedOptions.v_depth
            ? _c(
                "div",
                {
                  staticClass: "length__block-select product__select",
                  attrs: { tabindex: "1" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.state.selectedOptions.v_depth.vDepth + "cm") +
                      "\n        "
                  ),
                  _c(
                    "div",
                    { staticClass: "length__block-variants product__variants" },
                    _vm._l(_vm.const_v_depth, function (depth_variant) {
                      return _c(
                        "div",
                        {
                          staticClass: "length__block-variant",
                          class: {
                            "length__block-variant_active":
                              depth_variant.id ===
                              _vm.state.selectedOptions.v_depth.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeVariant(depth_variant, "v_depth")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(depth_variant.vDepth + "cm") + "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.custom && !_vm._store.isMobile()
        ? [
            this.customMax < _vm.customLength
              ? _c("div", { staticClass: "length__error" }, [
                  _vm._v("Max " + _vm._s(this.customMax) + " cm\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            this.customMin > _vm.customLength
              ? _c("div", { staticClass: "length__error" }, [
                  _vm._v("Min " + _vm._s(this.customMin) + " cm\n    "),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm._store.isMobile()
        ? _c(
            "div",
            { staticClass: "length__block-custom-block" },
            [
              !_vm.custom
                ? _c(
                    "div",
                    {
                      staticClass: "length__block-custom-length",
                      on: { click: _vm.changeCustom },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm._translate("Length to the cm exact")) +
                          "\n    "
                      ),
                    ]
                  )
                : [
                    _c(
                      "div",
                      {
                        staticClass: "length__block-custom-length sans-serif",
                        on: { click: _vm.changeCustom },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm._translate("Remove custom length")) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm._f("toEur")(_vm.price)))]),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }