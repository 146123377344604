var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__cutout-option" },
    [
      _c("expand-horizontal-option", {
        class: {
          expand__option_active: _vm.isShow,
          expand__option_disabled: _vm.disabled && !_vm.isShow,
        },
        attrs: {
          title: _vm.option.title,
          info: _vm.option.vText,
          image: _vm.cover,
          cost: _vm.option.vPriceModificator,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.toggle.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _vm.isShow &&
      (Object.keys(_vm.availableSlots).length ||
        Object.keys(_vm.option.vAvailPosition).length)
        ? _c("div", { staticClass: "product__cutout-wrapper" }, [
            Object.keys(_vm.availableSlots).length
              ? _c("div", { staticClass: "product__cutout-section" }, [
                  _c(
                    "div",
                    { staticClass: "product__cutout-section-count" },
                    _vm._l(_vm.availableSlots, function (variants, cutType) {
                      return _c("div", [
                        _c(
                          "div",
                          { staticClass: "product__cutout-section-title" },
                          [
                            _vm._v(
                              _vm._s(_vm._translate("Amount slots")) +
                                " " +
                                _vm._s(_vm._translate(cutType))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "product__cutout-select",
                              "product__select",
                              { active: _vm.isActive },
                            ],
                            attrs: { tabindex: "1" },
                            on: { click: _vm.removeActiveState },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.additionInfo.slots[cutType]) +
                                "\n            "
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "product__cutout-variants product__variants",
                              },
                              _vm._l(variants, function (count, i) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "product__cutout-variant",
                                    class: {
                                      "product__cutout-variant_active":
                                        _vm.additionInfo.slots[cutType] ===
                                        count,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeSlots(cutType, count)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(count))]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            Object.keys(_vm.option.vAvailPosition).length
              ? _c("div", { staticClass: "product__cutout-section" }, [
                  _c("div", { staticClass: "product__cutout-section-title" }, [
                    _vm._v(_vm._s(_vm._translate("Position"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "product__cutout-select-positions",
                      class: {
                        "product__cutout-select-positions_full":
                          Object.keys(_vm.availableSlots).length > 1,
                      },
                    },
                    _vm._l(_vm.option.vAvailPosition, function (position, i) {
                      return _c("expand-horizontal-option", {
                        key: i,
                        staticClass: "product__cutout-select-position f7",
                        class: {
                          "product__cutout-select-position_left":
                            position.label === "Left",
                          "product__cutout-select-position_full":
                            Object.keys(_vm.availableSlots).length > 1,
                          "product__cutout-select-position_active":
                            _vm.translateSentence(
                              _vm.cutoutTitle(position.label)
                            ) ===
                            _vm.translateSentence(_vm.additionInfo.position),
                        },
                        attrs: {
                          title: _vm.translateSentence(
                            _vm.cutoutTitle(position.label)
                          ),
                          image: _vm.positionImage(position.label),
                          "image-size": "42% auto",
                          cutout: true,
                          short: true,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.changePosition(position.label)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }