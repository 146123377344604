<template>
  <div class="filter__wrapper" :class="className">
    <div class="filter__overlay" @click="closeSection"></div>
    <div class="filter__title">
      {{ title }}
      <button class="filter__close"
              type="button"
              v-if="$parent.isMobile"
              title="Close filter option"
              @click="closeSection">
        <svg class="v-mid" width="12" height="12" style="fill:currentColor"><path d="M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6"/></svg>
      </button>
    </div>
    <div class="filter__options"
         :class="type">
      <a :href="href"
         class="custom-length__link"
         v-if="property === 'v_len'
          && customLengthProduct">
        {{ _translate('Length to cm exact or choose a standard length below') }}
      </a>
      <component :is="type"
                 v-for="option in items"
                 :key="option.id"
                 :is-clearable="isClearable"
                 :title="computedOptionTitle(option)"
                 :value="option.id"
                 :modelValue="type==='color'?modelValue:modelValue.id"
                 :short="short"
                 @clear="$emit('clear')"
                 :note="computedNote"
                 v-bind="{color:type==='color'?option:''}"
                 :is-new="option.isNew"
                 @change="changeValue(option)"/>
      <div v-if="property === 'v_cutouts'"
           class="filter__reset"
           @click="changeValue({})">{{ resetButtonText_ }}
      </div>
      <div v-if="thisShownItemsCount > 0 && this.options.length > thisShownItemsCount"
           class="filter__show-more"
           @click="showAllItems">{{ showButtonText_ }}
      </div>
    </div>
    <div class="filter__note" v-if="type === 'color' && _store.isMobile()">
      * {{ _translate('Choose a custom color during the ordering process') }}
    </div>
  </div>
</template>

<script>
import RadioButton from './RadioButton.vue'
import ColorRadioButton from './ColorRadioButton.vue'
import store from '../store'

export default {
  name: 'FilterSection',
  components: {
    radio: RadioButton,
    color: ColorRadioButton,
  },
  data () {
    return {
      thisShownItemsCount: 0,
      resetButtonText_: this.resetButtonText?this.resetButtonText:this._translate('Show all extensions'),
      showButtonText_: this.showButtonText?this.showButtonText:this._translate('More lengths'),
    }
  },
  props: {
    isClearable: {
      type: Boolean,
      default: false,
    },
    customLengthProduct: {
      type: Object,
      required: false,
    },
    mayOff: {
      type: Boolean,
      default: false,
    },
    className:{
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
    type: {
      type: String,
      default: 'radio',
    },
    short: {
      type: Boolean,
      default: false,
    },
    resetButtonText: {
      type: String,
    },
    showButtonText: {
      type: String,
    },
    shownItemsCount: {
      type: Number,
      default: 0,
    },
    property: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    configuration() {
      let configuration = {}
      if (typeof store.state.configurations[this.customLengthProduct.id] !== 'undefined') {
        for (const key in store.state.configurations[this.customLengthProduct.id]) {
          try {
            if (store.state.configurations[this.customLengthProduct.id].hasOwnProperty(key) && this.filters.hasOwnProperty(key)) {
              configuration[key] = this.filters[key].find(item => +item.id === store.state.configurations[this.customLengthProduct.id][key])
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      return configuration
    },
    href () {
      let params = '?'
      let properties = ['v_len', 'v_depth']
      if (this.customLengthProduct.typeHandle === 'newAccessories') {
        properties = ['v_depth']
      }

      for (const key in store.state.selectedOptions) {
        if (properties.indexOf(key) !== -1 && store.getSelectedOption(key).id) {
          params += `${key}=${store.getSelectedOption(key).id}&`
        }
      }
      for (const key in this.configuration) {
        if (properties.indexOf(key) === -1 && this.configuration.hasOwnProperty(key)) {
          params += `${key}=${this.configuration[key].id}&`
        }
      }

      return this.customLengthProduct.vmodelCategory[this.customLengthProduct.vmodelCategory.length - 1]
          + `/${this.customLengthProduct.slug}--${store.getSelectedOption('v_color').slug}${params.slice(0, -1)}#customize`
    },
    items () {
      return this.options.slice(0, this.thisShownItemsCount ? this.thisShownItemsCount : this.options.length)
    },
    modelValue () {
      return store.getSelectedOption(this.property)
    },
    _store() {
      return store
    },
    computedNote () {
      if (this._store.isMobile()) {
        return '*'
      }

      return null
    }
  },
  mounted () {
    this.thisShownItemsCount = this.shownItemsCount
  },
  methods: {
    _translate (key) { return store._translates(key)},
    changeValue (value) {
      store.setSelectedOption(this.property, value)
      // if (value.slug === 'accessories') {
      //   store.setSelectedOption('v_depth', {
      //     id: 24495,
      //     slug: 'd22',
      //     title: 'D22',
      //     vDepth: 22,
      //     vPriceModificator: 40
      //   });
      // }
      if (typeof value !== 'undefined') {
        this.$emit('input', value.id);
        this.$parent.changeFilterSection(this.label);
      }
    },
    showAllItems () {
      this.thisShownItemsCount = 0
    },
    computedOptionTitle (option) {
      let title = ''

      switch (this.property) {
        case 'v_len':
          title = `${option.vMinLen} cm`
          break

        case 'v_depth':
          title = `${option.vDepth} cm`
          break

        default:
          title = option.title
          break
      }

      return title
    },
    closeSection() {
      this.$parent.closeSection(this.label);
    }
  },
}
</script>

<style scoped lang="scss">
.custom-length__link {
  display: block;
  margin-bottom: 20px;

  @media screen and (max-width: 60em) {
    margin-bottom: 0;
    padding: 12px 0;
    text-align: center;
  }

  color: #ffd600;
  transition: 0.3s;

  &:hover {
    text-decoration: underline;
  }
}
.filter {
  &__note {
    margin-top: 2rem;
    margin-left: 1rem;
  }

  &__wrapper {
    margin-bottom: 3rem;
  }

  .mw300{
    max-width: 300px;
  }

  &__reset {
    line-height: 1.5rem;
    cursor: pointer;
    margin-top: 0.3rem;
  }

  &__show-more {
    line-height: 1.5rem;
    cursor: pointer;
    margin-top: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Ladislav, sans-serif;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 60em) {
      margin-bottom: 1.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__close {
    display: flex;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
    border: none;
  }

  &__options {
    &.color {
      display: flex;
      gap: 1rem;
      width: 9rem;
      flex-wrap: wrap;

      @media screen and (max-width: 60em) {
        width: 100%;
        padding: 0 0 0 1rem;
      }
    }
  }
}
</style>
