var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter__wrapper", class: _vm.className }, [
    _c("div", {
      staticClass: "filter__overlay",
      on: { click: _vm.closeSection },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "filter__title" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
      _vm.$parent.isMobile
        ? _c(
            "button",
            {
              staticClass: "filter__close",
              attrs: { type: "button", title: "Close filter option" },
              on: { click: _vm.closeSection },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "v-mid",
                  staticStyle: { fill: "currentColor" },
                  attrs: { width: "12", height: "12" },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter__options", class: _vm.type },
      [
        _vm.property === "v_len" && _vm.customLengthProduct
          ? _c(
              "a",
              { staticClass: "custom-length__link", attrs: { href: _vm.href } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._translate(
                        "Length to cm exact or choose a standard length below"
                      )
                    ) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.items, function (option) {
          return _c(
            _vm.type,
            _vm._b(
              {
                key: option.id,
                tag: "component",
                attrs: {
                  "is-clearable": _vm.isClearable,
                  title: _vm.computedOptionTitle(option),
                  value: option.id,
                  modelValue:
                    _vm.type === "color" ? _vm.modelValue : _vm.modelValue.id,
                  short: _vm.short,
                  note: _vm.computedNote,
                  "is-new": option.isNew,
                },
                on: {
                  clear: function ($event) {
                    return _vm.$emit("clear")
                  },
                  change: function ($event) {
                    return _vm.changeValue(option)
                  },
                },
              },
              "component",
              { color: _vm.type === "color" ? option : "" },
              false
            )
          )
        }),
        _vm._v(" "),
        _vm.property === "v_cutouts"
          ? _c(
              "div",
              {
                staticClass: "filter__reset",
                on: {
                  click: function ($event) {
                    return _vm.changeValue({})
                  },
                },
              },
              [_vm._v(_vm._s(_vm.resetButtonText_) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.thisShownItemsCount > 0 &&
        this.options.length > _vm.thisShownItemsCount
          ? _c(
              "div",
              {
                staticClass: "filter__show-more",
                on: { click: _vm.showAllItems },
              },
              [_vm._v(_vm._s(_vm.showButtonText_) + "\n    ")]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.type === "color" && _vm._store.isMobile()
      ? _c("div", { staticClass: "filter__note" }, [
          _vm._v(
            "\n    * " +
              _vm._s(
                _vm._translate(
                  "Choose a custom color during the ordering process"
                )
              ) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }